import React from 'react'
import { Link as GatsbyLink } from 'gatsby'


class Link extends React.Component {
	constructor(props) {
		super(props)

		this.handleClick = this.handleClick.bind(this)
		this.trackable = false
	}

	handleClick(e) {
		const { children, to, activeClassName, ...other } = this.props

		if (typeof window !== `undefined`) {
			const element = e.target
			const eventAction = other['data-track-event'] || 'Clicked Link'
			const event_category = other['data-track-location'] || document.location.pathname
			const event_label = other['data-track-label'] || element.textContent

			let trackingEventOptions = {
				event_action: eventAction,
				event_category: event_category,
				event_label: event_label,
				location: other['data-track-location'],
				text: element.textContent,
				category: 'Home Page',
				page: document.location.pathname,
				pageTitle: document.title,
				type: element.tagName.toLowerCase(),
			}
		//	console.log('EVENT' + JSON.stringify(trackingEventOptions))
			if (window.gtag) {
				window.gtag('event', eventAction, trackingEventOptions)
			}
		}
	}
	render() {
		const { children, to, activeClassName, ...other } = this.props
		const internal = /^\/(?!\/)/.test(to)

		if (internal) {
			return (
				<GatsbyLink to={to} activeClassName={activeClassName} {...other} onClick={this.handleClick}>
					{children}
				</GatsbyLink>
			)
		}
		return (
			<a href={to} {...other} onClick={this.handleClick}>
				{children}
			</a>
		)
	}
}

export default Link

const tBlue = "#109cb3"
const tGreen = "#00b398"
const tGreenSelected = "#008f79"
const Blues = "#136bb7"
const Cherry = "#b73913"
const Grass = "#4eb713"
const Honey = "#f1750e"
const Ink = "#333333"
const Fade = "#737373"
const Ghost = "#fafbfc"
const InputBorder = "#c1c7d0"
const Flour = "#ffffff"
const Concrete = "#e7e7e7"
const Dada = "#dadada" // did you mean another color?

export {
  tBlue,
  tGreen,
  tGreenSelected,
  Blues,
  Cherry,
  Grass,
  Honey,
  Ink,
  Fade,
  Ghost,
  InputBorder,
  Flour,
  Concrete,
  Dada,
}

import { css } from "styled-components";
import Polygon from "../images/polygon.svg";

const mixins = {
    flexCenter: css`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    flexSpaceBetween: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    flexColumnCenter: css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    background: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
    `,
    button: css`
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 0.875rem;
        min-width: 150px;
        min-height: 40px;

        @media (min-width: 1920px){
            min-height: 60px;
            min-width: 200px;
        }
    `,
    outlinedButton: css`
        color: var(--secondary-color);
        border: 2px solid var(--secondary-color);
        border-radius: 5px;
        background-color: transparent;
        transition: background-color 300ms ease,
                    color 300ms ease;
        
        &:hover{
            background-color: var(--secondary-color);
            color: #fff;
        }
    `,
    textWithPolygon: css`
        position: relative;

        span{
            position: relative;
            z-index: 2;
        }

        &::before{
            position: absolute;
            content: "";
            top: -20px;
            left: -20px;
            width: 30px;
            height: 30px;
            background: url(${Polygon}) no-repeat center / cover;
        }
    `,
    sliderImages: css`
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;    
        }
    `,
    mobileScreen: css`
        position: relative;

        img.screen{
            position: absolute;
            top: 7px;
            left: 8px;
            width: calc(100% - 15px);
            height: calc(100% - 14px);
            object-fit: cover;
            border-radius: 8px;
        }
    `
};

export default mixins;
import { createGlobalStyle } from "styled-components"
import { tGreen, Fade } from "../lib/colors"

const GlobalStyle = createGlobalStyle`
  :root{
    --primary-color: #273039;
    --secondary-color: #12A79E;
    --text-primary-color: #273039;
    --text-secondary-color: #727272;
    --light-green: #8CC63F;

    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;
  }

	*,
  *::after,
  *::before{
    box-sizing: border-box;
	}

  html{
    font-size: 16px;
  }

	body {
    margin: 0;
    font-family: Nunito, sans-serif !important;
    background-color: #F7F8F9 !important;
	}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin: 0;
  }

  img{
    max-width: 100%;
  }

	textarea, select, input, button { 
		outline: none;
  }

  .carousel {
    border-radius: 8px;
  }
  
  .carousel .control-dots {
    text-align: left !important;
  }

  .control-arrow {
    background: rgba(0,0,0,.2) !important;
  }

  .carousel .control-dots .dot.selected {
    width: 1rem !important;
    height: 1rem !important;
    background: ${tGreen} !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-transition: opacity 0.25s ease-in !important;
    -moz-transition: opacity 0.25s ease-in !important;
    -ms-transition: opacity 0.25s ease-in !important;
    -o-transition: opacity 0.25s ease-in !important;
    transition: opacity 0.25s ease-in !important;
    border-radius: 50% !important;
    width: 1rem !important;
    height: 1rem !important;
    cursor: pointer !important;
    display: inline-block !important;

    &:hover {
      opacity: 1 !important;
    }
  }

  .carousel .control-dots .dot {
    width: 1rem !important;
    height: 1rem !important;
    border: solid 1px ${Fade} !important;
    box-shadow: none !important;
    -webkit-transition: opacity 0.25s ease-in !important;
    -moz-transition: opacity 0.25s ease-in !important;
    -ms-transition: opacity 0.25s ease-in !important;
    -o-transition: opacity 0.25s ease-in !important;
    transition: opacity 0.25s ease-in !important;
    border: 1px solid ${Fade} !important;
    background: transparent !important;
    border-radius: 50% !important;
    width: 1rem !important;
    height: 1rem !important;
    cursor: pointer !important;
    display: inline-block !important;

    &:hover {
      opacity: 0.3 !important;
    }
  }
`

export default GlobalStyle

import React, { useState } from 'react';

//import { Link } from 'gatsby';
import Link from './Link'
import styled from 'styled-components';
import ChatWidget from '@icloudready/chat-widget'
import StartNowButton from "../components/StartNowButton"

import MobileMenuIcon from './MobileMenuIcon';

import { Flour, Ink, tBlue } from '../lib/colors';
import { maxWidth, sizes } from '../lib/layout';
import mixins from "../lib/mixins";
import Logo from '../images/mivors-logo.png';

const HeaderTag = styled.header`
	background-color: ${Flour};
	position: fixed;
	z-index: 10;
	width: 100%;
	box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.059);
`;

const CHAT_ACCOUNT_ID = process.env.CHAT_ACCOUNT_ID || '15a530bd-6ab0-4ccd-8596-465b98767efc'

const HeaderContainer = styled.div`
	max-width: ${maxWidth}px;
	margin: 0 auto;
	width: 100%;
	height: 5.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0 60px;

	@media (max-width: ${sizes.modifiedTablet}) {
		height: unset;
		padding: 20px;
		${({ open }) => {
			return open ? `box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);` : ``;
		}};
	}
`;

const TLink = styled(Link)`
  line-height: 2.29;
  letter-spacing: normal;
  text-decoration: none;
  color: ${({ isActive }) => {
		return isActive ? tBlue : Ink;
	}};
  font-size: 0.875rem;

  &:hover {
    color: ${tBlue};
    text-decoration: none;
  }
`;

const ExternalLink = styled.a`
	line-height: 2.29;
	letter-spacing: normal;
	text-decoration: none;
	color: ${Ink};
	font-size: 0.875rem;

	&:hover {
		color: ${tBlue};
		text-decoration: none;
	}
`;

const LogoImg = styled.img`
	width: 87px;
	height: 41px;

	@media (max-width: ${sizes.modifiedTablet}) {
		order: -1;
	}
`;

const LinksButtonContainer = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: ${sizes.modifiedTablet}) {
		${({ open }) => {
			return open
				? `width: 100%; order: 2; flex-direction: column; align-items: flex-start; z-index: 2; background-color: white;`
				: `display: none`;
		}};
	}
`;

const LinkContainer = styled.div`
	margin: 0 30px 0 0;

	@media (max-width: ${sizes.modifiedTablet}) {
		margin: 11px 0;
	}
`;

const ResponsiveButton = styled.button`
  cursor: pointer;
  ${mixins.button};
  ${mixins.outlinedButton};

  @media (max-width: ${sizes.modifiedTablet}) {
    display: none;
  }
`;

const MobileResponsiveButton = styled.button`
  display: none;
  cursor: pointer;

  @media (max-width: ${sizes.modifiedTablet}) {
    display: unset;
  }
`;

const MobileButtonLink = styled.a`
	display: none;
	text-decoration: none;

	@media (max-width: ${sizes.modifiedTablet}) {
		margin-left: auto;
		display: unset;
	}
`;

const ButtonLink = styled.a`
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
`;

const MobileMenuIconStyles = styled.div`
	display: none;

	@media (max-width: ${sizes.modifiedTablet}) {
		display: block;
		margin-left: 2rem;
	}
`;

const Header = React.memo(({ pathName }) => {
	const [ open, toggleOpen ] = useState(false);

	return (
		<>
		<HeaderTag>
			<HeaderContainer open={open}>
				<TLink to="/" 	data-track-location="header">
					<LogoImg src={Logo} />
				</TLink>
				<LinksButtonContainer open={open}>
					<LinkContainer>
						<TLink to="/erp" data-track-location="header" activeStyle={{ color: tBlue }} isActive={pathName === '/erp/'}>
							ERP
						</TLink>
					</LinkContainer>
					<LinkContainer>
						<TLink to="/hcm" data-track-location="header" activeStyle={{ color: tBlue }} isActive={pathName === '/hcm/'}>
							HCM
						</TLink>
					</LinkContainer>
					<LinkContainer>
						<TLink to="/cx" data-track-location="header" activeStyle={{ color: tBlue }} isActive={pathName === '/cx/'}>
							CX
						</TLink>
					</LinkContainer>
					<LinkContainer>
						<TLink to="/epm" data-track-location="header" activeStyle={{ color: tBlue }} isActive={pathName === '/epm/'}>
							BI & EPM
						</TLink>
					</LinkContainer>
					{/* <LinkContainer>
						<TLink
							to="/industries/realestate"
							activeStyle={{ color: tBlue }}
							isActive={pathName === '/industries/realestate/'}
						>
							Industries
						</TLink>
					</LinkContainer> */}
					{/* <LinkContainer>
						<ExternalLink href="https://medium.com/mivors/i-need-help-e7ede86f2191" target="_blank">
							Support
						</ExternalLink>
					</LinkContainer> */}
										 <StartNowButton/> 
					{/* <ButtonLink href="https://gamma.icloud-ready.com/marketplace" target="_blank">
						<ResponsiveButton>Start Now</ResponsiveButton>
					</ButtonLink> */}
				</LinksButtonContainer>
				{/* <MobileButtonLink target="_blank" href="https://gamma.icloud-ready.com/marketplace">
					 <MobileResponsiveButton>Start Now</MobileResponsiveButton> 
				</MobileButtonLink> */}
				<MobileMenuIconStyles
					onClick={() => {
						toggleOpen(!open);
					}}
				>
					<MobileMenuIcon />
				</MobileMenuIconStyles>
			</HeaderContainer>
		</HeaderTag>
		  <ChatWidget
		  accountId={CHAT_ACCOUNT_ID}
		  title="Welcome to mivors!"
		  subtitle="Ask us anything in the chat window below 😊"
		  newMessagePlaceholder="Start typing..."
		  // customer={{ external_id: customerId }}
		  primaryColor="#0097c2"
		  greeting="Hi there! How can I help you?"
		  baseUrl="https://beta.chat.idengager.com" //{process.env.CHAT_BASE_URL}
		/>
		</>
		    // accountId: "15a530bd-6ab0-4ccd-8596-465b98767efc",
    // baseUrl: "https://beta.chat.idengager.com",
    // enableStorytime: window.IDENGAGERCHAT_STORYTIME !== "disabled",
	);
});

export default Header;

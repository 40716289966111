import styled from "styled-components"
import { tGreen, Ink, Flour } from "../lib/colors"
import tracking from "../lib/tracking"
import { openPopupWidget } from "react-calendly";
import React from "react"


const pageSettings = {
  backgroundColor: 'ffffff',
  hideEventTypeDetails: false,
  hideLandingPageDetails: false,
  primaryColor: '00a2ff',
  textColor: '4d5055'
};

const url = 'https://calendly.com/mivors/request-for-free-trial';

const prefill = {
  // email: 'test@test.com',
  // firstName: 'Jon',
  // lastName: 'Snow',
  // name: 'Jon Snow',
  // guests: [
  //   'janedoe@example.com',
  //   'johndoe@example.com'
  // ],
  // customAnswers: {
  //   a1: 'a1',
  //   a2: 'a2',
  //   a3: 'a3',
  //   a4: 'a4',
  //   a5: 'a5',
  //   a6: 'a6',
  //   a7: 'a7',
  //   a8: 'a8',
  //   a9: 'a9',
  //   a10: 'a10'
  // },
  date: new Date(Date.now() + 86400000)
};

const utm={
  utmCampaign: 'Spring Sale 2019',
  utmContent: 'Shoe and Shirts',
  utmMedium: 'Ad',
  utmSource: 'Facebook',
  utmTerm: 'Spring'
};

const Button = styled.button`
  height: ${props => {
    return props.small ? `32px` : props.large ? `48px` : `40px`
  }};
  display: block;
  padding: 0 30px;
  border-radius: 4px;
  color: ${({ secondary }) => (secondary ? Ink : Flour)};
  background-color: ${({ tertiary, secondary }) =>
    secondary ? Flour : tertiary ? "Transparent" : tGreen};
  border: 1px solid ${({ tertiary }) => (tertiary ? Flour : tGreen)};
  font-family: Lato;
  font-size: 0.875rem;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  transition: all 300ms 0s ease;

  @media (hover: hover) {
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.4);
    }
  }
`

// const StartNowButton = ({ url, prefill, pageSettings, utm }) => {
  const StartNowButton = () => {
  const onClick = () => { openPopupWidget({ url, prefill, pageSettings, utm });
  tracking.eventTrack('interaction', 'click', 'start_now');
  }

  return <Button onClick={onClick}>Start Now</Button>;
};

export default StartNowButton
